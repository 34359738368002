import React, { useState, useEffect } from "react"
import Layout from "../../components/_layout"
import SEO from "../../components/seo"
import Jumbotron from "../../components/jumbotron"
import JumbotronSafari from "../../components/jumbotron-safari"
import Numbers from "../../components/numbers"
import SpinupHeading from "../../components/heading"
import Dots from "../../components/dots"
import Container from "../../components/_layout/container"
import { Box, Flex, Text, Heading, Image } from "rebass"
import Products from "../../assets/images/products/4.png"
import { motion } from "framer-motion"
import { useInView } from "react-intersection-observer"
import { isSafari } from "../../utils"
import Tabs from "../services/components/tabs"

const Home = () => {
  const [isBrowserSafari, setIsBrowserSafari] = useState(false)
  const [ref, inView] = useInView({ threshold: 0.2 })

  useEffect(() => {
    setIsBrowserSafari(isSafari())
  }, [setIsBrowserSafari])

  return (
    <Layout>
      <SEO
        title="The first Italian media tech company"
        description="Tech and marketing at the service of business"
      />
      {isBrowserSafari ? <JumbotronSafari /> : <Jumbotron />}

      <SpinupHeading
        py={[7, 9, 10]}
        subtitle="The first Italian media tech company"
        title={
          <>Whatever is the goal ahead, we always work with the same approach</>
        }
        color="background"
        backgroundColor="text"
      />
      <Container size="large" backgroundColor="text">
        <Dots
          py={[4, 5, 0]}
          pb={[3, 4, 10]}
          backgroundColor="text"
          dots={[
            {
              title: "Analysis",
              pointColor: "primary",
              borderColor: "white",
            },
            {
              title: "Action",
              pointColor: "primary",
              borderColor: "white",
            },
            {
              title: "Iteration",
              pointColor: "primary",
              borderColor: "white",
            },
          ]}
        />
      </Container>

      <Tabs />

      <Container size="large" backgroundColor="white">
        <Flex
          flexDirection={["column", "row"]}
          backgroundColor="background"
          flexWrap="wrap"
          mx={[0, -2]}
          py={[4, 5, 8]}
          px={[5, 0]}
          width="100%"
          justifyContent="center"
          alignItems="center"
          ref={ref}
        >
          <Flex
            pl={[0, 8, 8, 0]}
            pr={[0, 9]}
            mb={[7, 0]}
            width={["100%", 1 / 2]}
            flexDirection="column"
          >
            <Box sx={{ overflow: "hidden" }}>
              <motion.div
                style={{ position: "relative" }}
                initial={{ top: 120 }}
                animate={{ top: inView ? 0 : 120 }}
                transition={{
                  duration: 0.8,
                  ease: "easeOut",
                  delay: 0.4,
                }}
              >
                <Text color="primary" fontSize={[1, 2, 3]} mb={3}>
                  _
                </Text>
              </motion.div>
            </Box>

            <Box sx={{ overflow: "hidden" }}>
              <motion.div
                style={{ position: "relative" }}
                initial={{ top: 120 }}
                animate={{ top: inView ? 0 : 120 }}
                transition={{
                  duration: 0.8,
                  ease: "easeOut",
                }}
              >
                <Heading color="text" fontSize={[1, 2]} mb={5} fontWeight={800}>
                  Don't call us agency
                </Heading>
              </motion.div>
            </Box>

            <Text color="text" fontSize={[1]} fontWeight={300}>
              Spinup is not a performance marketing agency. Spinup is a media
              tech company who achieves results for itself and for its clients,
              and it does so by building proprietary technologies in support of
              a strong expertise on a global scale in the world of media buying.
            </Text>
          </Flex>

          <Box px={4} width={["100%", 1 / 2]} pb={[2, 0]}>
            <Image src={Products} />
          </Box>
        </Flex>
      </Container>

      <Numbers />
    </Layout>
  )
}

export default Home
