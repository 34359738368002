import React from "react"
import { Card, Text, Flex, Box } from "rebass"
import { useThemeUI } from "theme-ui"
import { motion } from "framer-motion"

const TabCard = ({
  description,
  bullets,
  numbers,
  backgroundColor = "text",
  color = "background",
}) => {
  const { theme } = useThemeUI()
  return (
    <Card
      p={[1, 4]}
      sx={{ textAlign: "center" }}
      backgroundColor={backgroundColor}
      color={color}
    >
      <Box sx={{ overflow: "hidden" }}>
        <motion.div
          style={{ position: "relative" }}
          initial={{ top: 120 }}
          animate={{ top: 0 }}
          transition={{
            duration: 0.6,
            ease: "easeOut",
          }}
        >
          <Text fontSize={[0, 1]}>{description}</Text>
        </motion.div>
      </Box>

      <Box sx={{ overflow: "hidden" }}>
        <motion.div
          style={{ position: "relative" }}
          initial={{ top: 260 }}
          animate={{ top: 0 }}
          transition={{
            duration: 0.6,
            ease: "easeOut",
            delay: 0.3,
          }}
        >
          <Flex
            flexDirection={["column", "row"]}
            justifyContent="center"
            alignItems="center"
            px={[0, 4]}
            my={7}
            mx={[0, 10]}
          >
            {bullets.map((bullet, i) => (
              <Flex
                width={[1 / 2, 1 / bullets.length]}
                key={i}
                sx={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  px: [3],
                  height: 40,
                  mx: [2],
                  mt: [3, 0],
                  borderRadius: theme.radii.default,
                  border: `1px solid ${theme.colors.primary}`,
                  color: "background",
                  lineHeight: 1,
                }}
              >
                <Box
                  sx={{
                    flexShrink: 0,
                    mr: 2,
                    width: [6, 10],
                    height: [6, 10],
                    borderRadius: 12,
                    backgroundColor: theme.colors.lightgreen,
                  }}
                />

                <Text fontSize={["12px", 0]}>{bullet}</Text>
              </Flex>
            ))}
          </Flex>
        </motion.div>
      </Box>

      <Box sx={{ overflow: "hidden" }}>
        <motion.div
          style={{ position: "relative" }}
          initial={{ top: 120 }}
          animate={{ top: 0 }}
          transition={{
            duration: 0.6,
            ease: "easeOut",
            delay: 0.6,
          }}
        >
          <Flex
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            mb={[3, 4]}
          >
            {numbers.map((number, i) => (
              <Flex
                key={i}
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                mx={[3]}
              >
                <Text
                  fontSize={[3, 64]}
                  sx={{ lineHeight: 1 }}
                  fontWeight="500"
                >
                  {number.n}
                </Text>

                <Text fontSize={["10px", 0]}>{number.label}</Text>
              </Flex>
            ))}
          </Flex>
        </motion.div>
      </Box>
    </Card>
  )
}

export default TabCard
