import React from "react"
import { Flex, Text, Box } from "rebass"
import { useThemeUI } from "theme-ui"
import ReactPlayer from "react-player"
import { Helmet } from "react-helmet"
import { css } from "emotion"
import { motion } from "framer-motion"
import Scroller from "../../components/scroller"

const JumbotronSafari = () => {
  const { theme } = useThemeUI()

  const title = ["We generate new customers"]
  const subtitle = [
    "The media-tech company with an innovative approach",
    "to creating qualified leads for your business",
  ]

  return (
    <>
      <Helmet>
        <link
          rel="preload"
          href="https://spinup-static-website.s3.eu-south-1.amazonaws.com/videos/final_draft.mp4"
          as="video"
        />
      </Helmet>

      <Flex
        sx={{
          flexDirection: "column",
          height: "100vh",
        }}
      >
        <motion.div
          className={css`
            position: relative;
            width: 100%;
            display: flex;
            height: 100vh;
            flex-direction: column;
            justify-content: flex-end;
            overflow: hidden;
          `}
          // initial={{
          //   opacity: 0,
          //   scale: 1,
          //   left: "10%",
          //   height: "20%",
          //   width: "80%",
          //   y: 600,
          // }}
          // animate={{
          //   opacity: 1,
          //   scale: 1,
          //   left: "0%",
          //   height: "100%",
          //   width: "100%",
          //   y: 0,
          // }}
          // transition={{
          //   duration: 1,
          //   ease: [0.175, 0.885, 0.32, 1],
          // }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 1,
              overflow: "hidden",
            }}
          >
            <motion.div
              style={{
                width: "100%",
                height: "100vh",
                zIndex: 1,
                objectFit: "cover",
              }}
            >
              <ReactPlayer
                playing
                playsinline
                loop
                muted
                url={
                  "https://spinup-static-website.s3.eu-south-1.amazonaws.com/videos/final_draft.mp4"
                }
                width="100%"
                height="100vh"
                className={css`
                  > video {
                    object-fit: cover !important;
                    height: 100% !important;
                  }
                `}
              />
            </motion.div>

            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 2,
                backgroundColor: "#000",
                opacity: 0.6,
              }}
            ></div>

            <motion.div
              style={{
                position: "absolute",
                bottom: "40px",
                zIndex: 3,
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                duration: 0.8,
                delay: 1.5,
              }}
            >
              <Scroller />
            </motion.div>
          </Box>

          <Flex
            flexDirection="column"
            justifyContent="center"
            p={[6, 9]}
            mb={[9, 10, 10, 0]}
          >
            <Box mb={[3, 5]} sx={{ zIndex: 3 }}>
              {title.map((text, i) => (
                <Box key={i} sx={{ overflow: "hidden" }}>
                  <Text
                    key={i}
                    fontSize={[3, 4, 4, "68px"]}
                    style={{ lineHeight: 1 }}
                    color={theme.colors.white}
                    fontWeight={"700"}
                  >
                    {text}
                  </Text>
                </Box>
              ))}
            </Box>

            <Box sx={{ zIndex: 3, overflow: "hidden" }}>
              {subtitle.map((text, i) => (
                <Text
                  key={i}
                  fontWeight="light"
                  fontSize={["24px"]}
                  style={{ lineHeight: 1.3 }}
                  color={theme.colors.white}
                >
                  {text}
                </Text>
              ))}
            </Box>
          </Flex>
        </motion.div>
      </Flex>
    </>
  )
}

export default JumbotronSafari
